import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginForm from "../components/part/loginForm"
import Banner from "../components/banner"

const LoginPage = () => (
  <Layout>
    <SEO title="Login" />
    <Banner title="My Account"></Banner>
    <LoginForm></LoginForm>
  </Layout>
)

export default LoginPage
