import React from "react"
import { connect } from "react-redux"
import { LOGIN } from "../../redux/actionTypes"
import { Users } from "../../redux/api"
import { Link, navigate } from "gatsby"
import { REGISTER, FORGOT_PASSWORD } from "../../constants/routes"
import { Form, Input, Alert } from "antd"
import {
  PROFILE,
  DASHBOARD_ADMIN,
  DASHBOARD_EDITOR,
  DASHBOARD_REVIEWER,
} from "../../constants/routes"

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: "", password: "", error: null }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleInputChange(event) {
    const target = event.target
    this.setState({ [target.name]: target.value })
  }
  getNextPage(role) {
    switch (role) {
      case 4:
        return DASHBOARD_ADMIN
      case 3:
        return DASHBOARD_EDITOR
      case 2:
        return DASHBOARD_REVIEWER
      default:
        return PROFILE
    }
  }
  async handleSubmit(event) {
    event.preventDefault()
    const { email, password } = this.state
    const result = await Users.login({ email, password })
    if (!result) return this.setState({ error: true })
    this.props.dispatch({ type: LOGIN, payload: await result.json() })
    const page = this.getNextPage(this.props.state.user.profile.role)
    navigate(page)
  }
  render() {
    return (
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div
              className="accordion accordion-lg mx-auto mb-0 clearfix"
              style={{ maxWidth: "550px" }}
            >
              <div
                className="accordion-header"
                style={{ marginBottom: "24px" }}
              >
                <div className="accordion-icon">
                  <i className="icon-user4" style={{ color: "#8f8f8f" }} />
                </div>
                <div className="accordion-title login-title">
                  Login to My Account
                </div>
              </div>
              <div className="clearfix">
                <form
                  id="login-form"
                  name="login-form"
                  className="row mb-0"
                  onSubmit={this.handleSubmit}
                >
                  <div className="col-12 form-group">
                    <label htmlFor="login-form-email">E-mail</label>
                    <div className="input-group">
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="icon-email3" />
                        </div>
                      </div>
                      <input
                        type="text"
                        id="login-form-email"
                        name="email"
                        className="form-control"
                        placeholder="yourname@mail.com"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 form-group">
                    <label htmlFor="login-form-password">Password</label>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Please input your password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                    <span
                      toggle="#password"
                      className="fa fa-fw fa-eye field-icon toggle-password"
                    />
                  </div>
                  {this.state.error && (
                    <div className="col-12 form-group mar-log">
                      <Alert
                        message="Login error, please check your credentials and try again"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  <div className="col-12 form-group mar-log">
                    <input
                      className="button button-3d button-circle button-log gradient-grey-orange mr-0"
                      type="submit"
                      value="Login"
                    />
                    <Link to={FORGOT_PASSWORD} className="float-right pd-20">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="col-12 form-group">
                    <Link
                      to={REGISTER}
                      className="button button-circle button-border button-white button-reg mr-0 text-center"
                    >
                      Register New Account
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default connect(state => ({ state }))(LoginForm)
